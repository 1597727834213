import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "../fonts/vsf_fonts";
import dayjs from "dayjs";
import { COMPLETE } from "../../constants/Refers/referralStatus";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
};
export const printAppointment = ({ referral, fromHospName, toHospName }) => {
  function dateToLocalDateString(date) {
    if (!date || new Date(date).toString() === "Invalid Date") return "";
    return new Date(date).toLocaleDateString("th-TH", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
  function dateToLocalTimeString(date) {
    if (!date || new Date(date).toString() === "Invalid Date") return "";
    return new Date(date).toLocaleTimeString("th-TH");
  }
  const expireDate = (referral) => {
    if (referral.data.expireDate) {
      return dateToLocalDateString(referral.data.expireDate?.toString());
    }
    if (referral.data.referDateTime) {
      const dateOfDayjs = dayjs(referral.data.referDateTime).add(3, "months");
      return `${dateToLocalDateString(dateOfDayjs?.toString())} (อัตโนมัติ)`;
    }
    return "-";
  };

  const splitClinic = (point) => {
    const splits = point.split("คลินิก");
    if (splits.length === 0) return "";
    return splits[0];
  };

  const pdfContent = (referral) => {
    return [
      {
        text: "ใบนัดส่งตัว",
        style: ["header", "center"],
        pageOrientation: "portrait",
      },
      {
        columns: [
          {
            text: [{ text: "จาก รพ. " }, fromHospName],
            style: "normalText",
          },
          {
            text: [{ text: "ถึง รพ. " }, toHospName],
            style: "normalText",
          },
          {
            text: [{ text: "เลขที่ใบส่งตัว " }, referral.data?.referNumber],
            style: "normalText",
          },
        ],
      },
      // patient info
      {
        layout: "headerLineOnly", // optional
        table: {
          widths: [120, 70, 200],
          body: [
            [
              {
                text: [
                  {
                    text: "วันที่ส่งตัว ",
                    width: "auto",
                  },
                  dateToLocalDateString(
                    referral.data.referDateTime?.toString()
                  ),
                ],
                style: "normalText",
              },
              {
                text: [
                  { text: "เวลา ", width: "auto" },
                  dateToLocalTimeString(
                    referral.data.referDateTime?.toString()
                  ),
                  "  น.",
                ],
                style: "normalText",
              },
              {
                text: [
                  {
                    text: "สิ้นสุดประสาน เวลา  ",
                    width: "auto",
                  },
                  expireDate(referral),
                ],
                style: "normalText",
              },
            ],
          ],
        },
      },
      {
        layout: "headerLineOnly", // optional
        table: {
          widths: [180, 160],
          body: [
            [
              {
                text: [
                  {
                    text: "เลขบัตรประชาชน",
                    width: "auto",
                  },
                  "  ",
                  {
                    text: referral?.data?.cid,
                    style: "bold",
                    width: "auto",
                  },
                ],
                style: "normalText",
              },

              {
                text: [
                  {
                    text: "ชื่อ-สกุล   ",
                    width: "auto",
                  },
                  referral?.data?.ptname,
                ],
                style: "normalText",
              },
            ],
          ],
        },
      },
      {
        layout: "headerLineOnly", // optional
        table: {
          widths: [180],
          body: [
            [
              {
                text: [
                  {
                    text: "HN ",
                    width: "auto",
                  },
                  referral?.data?.hn,
                ],
                style: "normalText",
              },
            ],
          ],
        },
      },
      referral?.appointmentDateTime &&
        referral.status === COMPLETE && {
          layout: {
            vLineColor: function (i, node) {
              return i === 0 || i === node.table.widths.length
                ? "black"
                : "white";
            },
            hLineColor: function (i) {
              return i === 2 && "white";
            },
          }, // optional
          table: {
            widths: [300],
            body: [
              [
                {
                  text: `วันเวลานัด: ${new Date(
                    referral?.appointmentDateTime
                  ).toLocaleDateString("th-TH", {
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}) น.`,
                  style: "bold",
                },
              ],
              [
                {
                  text: `จุดนัดหมาย: ${splitClinic(
                    referral?.appointmentPoint || "-"
                  )}`,
                  style: "bold",
                },
              ],
              [
                {
                  text: `นัดพบแพทย์: ${referral?.appointmentDoctor || "-"}`,
                  style: "bold",
                },
              ],
              [
                {
                  text: `หมายเหตุ: ${referral?.appointmentNotes || "-"}`,
                  style: "bold",
                },
              ],
            ],
          },
        },
    ];
  };
  var docDefinition = {
    content: pdfContent(referral),
    defaultStyle: {
      font: "THSarabunNew",
      fontSize: 12,
    },
    styles: {
      logoText: { bold: true, fontSize: 10, margin: [10, 0, 0, 0] },
      header: { bold: true, alignment: "center", fontSize: 16 },
      bold: { bold: true, fontSize: 16 },
      center: { alignment: "center" },
      right: { alignment: "right" },
      marginTop: { margin: [0, 5, 0, 0] },
      normalText: { fontSize: 14 },
      marginLeft: { margin: [5, 0, 0, 0] },
      marginTopSpace: { margin: [0, 80, 0, 0] },
      tabLeft: { margin: [60, 0, 0, 0] },
    },
    pageSize: "A5",
    pageOrientation: "portrait",
  };
  pdfMake.createPdf(docDefinition).open();
};
