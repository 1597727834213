export function getApiUrl() {
  const hostname = window.location.hostname;

  if (hostname !== "localhost") {
    const apiUrl = "https://" + hostname.replace("refer", "refer-api");

    return apiUrl;
  } else {
    return "http://localhost:3010";
  }
}

export function isConfirmationExpired(
  dateTime: string | number | Date
): boolean {
  const confirmationExpirationTime = new Date(dateTime);
  confirmationExpirationTime.setHours(
    confirmationExpirationTime.getHours() + 2
  );
  return new Date() > confirmationExpirationTime;
}

export const getBase64ImageFromURL = (url: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = url;
  });
};
